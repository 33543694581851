export const fitInBox = function (box, image) {
  var box_ratio = box.width/box.height;
  var image_ratio = image.width/image.height;
  if (box_ratio>image_ratio) {
    var width = Math.round(box.height*image_ratio);
    return {width:width,height:box.height};
  } else {
    var height = Math.round(box.width/image_ratio);
    return {width:box.width,height:height};
  }
};

const pp = function (viewport, image) {
  
  var margin_percent = 5;
  
  var align = 'center';
  var valign = 'middle';
  
  var start_aspect = 2/3;
  var end_aspect = 3/2;
  
  var viewport_width = viewport.width;
  var viewport_height = viewport.height;
  
  var aspect_ratio = image.width/image.height;

  var portrait = false;
  if (viewport_height > viewport_width) {
    portrait = true;
    viewport_height = [viewport_width, viewport_width = viewport_height][0];
    aspect_ratio = 1/aspect_ratio;
  }

  var margin = viewport_height/100*margin_percent;
  
  var zero_margin = 440;
  var min_margin = 4;
  var some_margin = 900;
  var add_margin = 30;
  margin = min_margin;
  if (viewport_height>zero_margin) {
    margin = min_margin+(viewport_height-zero_margin)*(add_margin/(some_margin-zero_margin));
  }

  var container_width = viewport_width-(margin*2);
  var container_height = viewport_height-(margin*2);

  var start_percent = 60;

  var canvas_ratio = container_width/container_height;

  var wizardry = canvas_ratio-1;

  var resulting_percent = start_percent+((100-start_percent)/(end_aspect-start_aspect)*(aspect_ratio-start_aspect));
  var start_surface = container_height*(container_height*start_aspect)/(start_percent/100);
  var one_surface = container_height*(container_height*start_aspect);
  var resulting_surface = start_surface/100*resulting_percent;

  resulting_surface = one_surface + (resulting_surface-one_surface)*wizardry;

  var resulting_width = aspect_ratio*Math.sqrt(resulting_surface/aspect_ratio);
  var resulting_height = resulting_width/aspect_ratio;

  if (resulting_width>container_width) {
    resulting_width = container_width;
    resulting_height = resulting_width/aspect_ratio;
  } else if (resulting_height>container_height) {
    resulting_height = container_height;
    resulting_width = resulting_height*aspect_ratio;
  }

  if (portrait === true) {
    container_height = [container_width, container_width = container_height][0];
    resulting_height = [resulting_width, resulting_width = resulting_height][0];
    viewport_height = [viewport_width, viewport_width = viewport_height][0];
    aspect_ratio = 1/aspect_ratio;
  }

  var image_left = margin;
  var image_right = margin;

  var image_top;

  if (align == "right") {
    image_left = viewport_width - resulting_width;
  } else if (align == "center") {
    image_left = Math.round((viewport_width - resulting_width) / 2);
  }
  if (valign == "middle") {
    image_top = Math.round((viewport_height - resulting_height) / 2);
  } else if (valign == "bottom") {
    image_top = viewport_height - resulting_height;
  }
  
  return {'width':resulting_width, 'height':resulting_height, 'left':image_left, 'top':image_top};
};

export default pp;