import { h } from 'preact';

import PP from './PP/PP'

const App = () => (
	<div id="app" onClick={() => { document.body.webkitRequestFullScreen(); document.body.style.cursor = 'none'; }}>
		<PP key="1" />
		<PP key="2" />
		<PP key="3" />
	</div>
)

export default App;
