import { h, Component } from 'preact'
import pp from '../../lib/pp'

// Robert Penner's easeInOutQuad - http://robertpenner.com/easing/
const easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) return c / 2 * t * t + b
  t--
  return -c / 2 * (t * (t - 2) - 1) + b
}

export default class PP extends Component {

  constructor() {
    super()
    this.state = {
      canvas: this.getCanvasDimensions(),
      ratio: this.newRatio()
    }
    this.setRatio = this.setRatio.bind(this)
    this.setCanvasDimensions = this.setCanvasDimensions.bind(this)
  }

  componentDidMount() {
    this.scrollListener = window.addEventListener('resize', this.setCanvasDimensions)
    setInterval(this.newSlide.bind(this), Math.round(Math.random() * 5000) + 800)
  }

  getCanvasDimensions() {
    return { width: window.innerWidth, height: window.innerHeight }
  }

  newRatio() {
    const ratios = [3 / 2, 5 / 4, 4 / 3, 16 / 9, 2 / 1, 24 / 9, 1 / 1, 3 / 4, 2 / 3];
    // return ratios[Math.floor(Math.random()*ratios.length)];
    return .5 + Math.random();
  }

  setRatio(ratio) {
    this.setState({ ratio })
  }

  newSlide() {
    const new_aspect_ratio = this.newRatio();
    const opt = {
      duration: 800,
      easing: easeInOutQuad
    }

    const { setRatio, state } = this;
    const current_ratio = state.ratio;

    const loop = function (time) {
      const timeElapsed = time - timeStart;

      const percentage = opt.easing(timeElapsed, 0, 100, opt.duration);
      const ratio = current_ratio + (new_aspect_ratio - current_ratio) / 100 * percentage;
      setRatio(ratio);

      if (timeElapsed < opt.duration) {
        requestAnimationFrame(loop)
      } else {
        // end
        setRatio(new_aspect_ratio);
      }
    }

    let timeStart;

    requestAnimationFrame(function (time) {
      timeStart = time;
      loop(time);
    }.bind(this));

  }

  setCanvasDimensions() {
    this.setState({
      canvas: this.getCanvasDimensions()
    })
  }

  render() {

    var viewport = this.state.canvas;
    //
    var slide = { width: 1, height: this.state.ratio };
    //    //
    var size_and_pos = pp(viewport, slide);

    return <div style={{ left: size_and_pos.left, top: size_and_pos.top, width: size_and_pos.width, height: size_and_pos.height, border: '2px solid black', position: 'fixed' }}></div>
  }
}